export let MAINDOMAIN_LANDING = 'https://landing.devrpay.com';

export let BASE_URL = 'https://dmeqm44l6k.execute-api.us-east-2.amazonaws.com';
//'https://api.devrpay.com';
export let TENANT_DOMAIN = `${BASE_URL}/tenant`;
export let CATALOG_DOMAIN = `${BASE_URL}/catalog`;
export let STAFF_DOMAIN = `${BASE_URL}/staff`;
export let CUSTOMER_DOMAIN = `${BASE_URL}/customer`;
export let AUTH_DOMAIN = `${BASE_URL}/auth`;
export let POS_DOMAIN = `${BASE_URL}/pos`;
export let CLOUD_DOMAIN = `${BASE_URL}/payment/clover`;
export let POS_PREVIOUS_DOMAIN = `https://business-settings.devrpay.com`;

export const CLOVER_DOMAIN = `http://localhost:5000`;

switch (`${process.env.REACT_APP_ENV}`.trim().toLowerCase()) {
  case 'dev':
    MAINDOMAIN_LANDING = 'https://landing.devrpay.com';
    BASE_URL = `https://api.devrpay.com`;
    POS_PREVIOUS_DOMAIN = `https://business-settings.devrpay.com`;
    break;
  case 'staging':
    MAINDOMAIN_LANDING = 'https://landing.stage.devrpay.com';
    BASE_URL = 'https://api-stage.devrpay.com';
    POS_PREVIOUS_DOMAIN = `https://business-settings.stage.devrpay.com`;
    break;
  case 'prod':
    MAINDOMAIN_LANDING = 'https://landing.getrichpos.com';
    BASE_URL = `https://api.getrichpos.com`;
    POS_PREVIOUS_DOMAIN = `https://app.getrichpos.com/`;
    break;
  default:
    break;
}

TENANT_DOMAIN = `${BASE_URL}/tenant`;
CATALOG_DOMAIN = `${BASE_URL}/catalog`;
STAFF_DOMAIN = `${BASE_URL}/staff`;
CUSTOMER_DOMAIN = `${BASE_URL}/customer`;
AUTH_DOMAIN = `${BASE_URL}/auth`;
POS_DOMAIN = `${BASE_URL}/pos`;
CLOUD_DOMAIN = `${BASE_URL}/payment/clover`;

export const AppDomain = {
  MAINDOMAIN_LANDING,
  BASE_URL,
  TENANT_DOMAIN,
  CATALOG_DOMAIN,
  STAFF_DOMAIN,
  CUSTOMER_DOMAIN,
  AUTH_DOMAIN,
  POS_DOMAIN,
  CLOUD_DOMAIN,
  CLOVER_DOMAIN,
};

export default AppDomain;
